<template>
    <div class="p-4 h-100">
        <div class="d-flex">
            <div class="input-group-prepend">
                <span 
                    class="input-group-text brl" 
                    id="basic-addon1" 
                    style="
                        font-size: 2rem;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    "
                >$</span>
            </div>
            <currency-input
                :value="amount"
                @change="amount = $event"
                class=""
                style="
                    font-size: 2rem;
                    text-align: center;
                    border-top-right-radius: 15px; 
                    border-bottom-right-radius: 15px; outline: none;
                    border: 1px solid #ced4da;
                    background-color: #e9ecef;
                    color: #7BAA64;
                "
                :options="{
                    currency: 'USD',
                    currencyDisplay: 'hidden',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    useGrouping: true,
                    valueRange: { min: 0, max: 100000 },
                }"
            />

            <div @click="amount = availableBalance/100" class="p-2 ml-2" style="cursor: pointer; border: 1px solid black; border-radius: 15px; height: 60px; line-height: 42px; vertical-align: center; font-size: 2rem;"> MAX</div>
        </div>

        <div class="mt-1 text-center">Available balance: ${{ (availableBalance / 100).toFixed(2) }}</div>

        
        <!-- Payment specific section -->
        <div class="mt-3">
            <div class="form-row">
                <div class="col">
                    <div class="form-group d-flex">
                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    height: 38px;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            ><icon-email class="iconcustom" /></span>
                        </div>
                        <input type="email" class="form-control" id="email" placeholder="PayPal Email Address" v-model="email">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col">
                    <div class="form-group d-flex">

                        <div class="input-group-prepend">
                            <span 
                                class="input-group-text brl" 
                                id="basic-addon1" 
                                style="
                                    height: 38px;
                                    border-top-right-radius: 0px;
                                    border-bottom-right-radius: 0px;
                                "
                            >Fee</span>
                        </div>
                        <input type="text" class="form-control" disabled v-model="transactionFee">
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center h3 mt-3">
            <b>Total:</b> ${{this.netAmount}}
        </div>

        <div class="text-center mt-2">
            <button v-if="loading" class="btn btn-sm btn-orange"><div class="spinner-border text-white spinner-sm" role="status"></div></button>
            <div v-else class="btn btn-orange" @click="withdraw">Withdraw</div>
        </div>
        <div class="mt-1 text-center" style="font-size: 0.8rem;">All transactions are processed in USD. User is responsible for transaction fees.</div>
    </div>
</template>

<script>
import api from "@/api.js";
import Swal from "sweetalert2";
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            email: "",
            availableBalance: 0,
            amount: 0,
            loading: false,
        }
    },
    mounted() {
        console.log("PayPal mounted");
        if (this.user) {
            this.email = this.user.email || "";
            if (this.user.payment_info) {
                this.email = this.user.payment_info.paypalEmail
            }
        }
        // this.amount = this.availableToWithdraw || 0;
        this.availableBalance = this.user.salesBalance;

        console.log("Getting available balance");
    },
    methods: {
        async withdraw() {
            this.loading = true;
            if (!this.email) {
                this.loading = false;
                Swal.fire('Error', 'Please enter an email address', 'error');
                return
            }

            this.$emit('withdraw', {
                amount: this.amount,
                paypalAddress: this.email,   
            });
        }
    },
    computed: {
        ...mapGetters({ 'user': 'auth/user'  }),
        fee() {
            return 0.25;// (Math.min(20, (this.amount * 0.02) + 0.25)).toFixed(2);
        },
        transactionFee() {
            return "$" + this.fee + ''
        },
        netAmount() {
            return Math.max(0, this.amount - this.fee).toFixed(2);
        }
    }


}
</script>

<style lang="scss" scoped>

.material-design-icon.iconcustom {
  height: 20px;
  width: 25px;
  /* padding: */
}
.material-design-icon.iconcustom > .material-design-icon__svg {
  height: 1.5em;
  width: 1.5em;
}
</style>