import { render, staticRenderFns } from "./PayPal.vue?vue&type=template&id=97a1bc1a&scoped=true&"
import script from "./PayPal.vue?vue&type=script&lang=js&"
export * from "./PayPal.vue?vue&type=script&lang=js&"
import style0 from "./PayPal.vue?vue&type=style&index=0&id=97a1bc1a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a1bc1a",
  null
  
)

export default component.exports